import './ContactUs.css';
import ServicesCard from './ServicesCard';

const ContactUs = () =>{
    return(
        <div className="footer" id="contactUs">
            <h1 className="title">Contact Us</h1>
            <p className="address">Grimm Masonry, LLC 655 Heather Lane Oconomowoc, WI 53066</p> 
            <ul className="container_for_workers">
              <li className="worker">Barry Grimm
                <ul className="info">
                  <li>262-490-7517 (cell)</li>
                  <li>bgrimm04@gmail.com</li>
                </ul>
              </li>
              <li className="worker">Casey Grimm
                <ul className="info">
                  <li>414-940-4476 (cell)</li>
                  <li>caseytg@sbcglobal.net</li>
                </ul>
              </li>
            </ul>
        </div>
    )
}

export default ContactUs;