import './Header.css';

const Header = () => {
    return ( 
        <>
        <div class='text-on-image'>
          <img class= 'logo' src={require ('../WebsitePictures/RealLogo.png')} alt="Logo"/>
        </div> 
        </>
  )
}

export default Header;
