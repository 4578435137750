import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.css';

const Navbar = () => {
    return(
        <>
          <div style={{ display: 'block', width: '100%', padding: 20, marginBottom: 1000, marginTop: -10, backgroundColor: 'white'}}>
            <Nav activeKey="./AboutUs" activeKey="./Services" activeKey="./ContactUs">
                <Nav.Item>
                    <Nav.Link href="#aboutUs">About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#services">Services</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#contactUs">Contact Us</Nav.Link>
                </Nav.Item>
            </Nav>
          </div>
        </>
    )
}

export default Navbar;