import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Magazine from './components/Magazine';
import OurWork from './components/OurWork';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

AOS.init();

export default function App() {
  return (
        <>
          <div className='navbar'>
            <Navbar />
          </div>
          <div className='header' data-aos="fade-in" data-aos-delay="150" data-aos-duration="2000">
             <Header />
          </div>
          <div className='aboutUs' data-aos="fade-in" data-aos-delay="150" data-aos-duration="2000">
            <AboutUs />
          </div>
          <div className='aboutUs' data-aos="fade-in" data-aos-delay="150" data-aos-duration="2000">
            <Magazine />
          </div>
          <div clssName='services' data-aos="fade-in" data-aos-delay="150" data-aos-durration="2000">
            <Services />
          </div>
          <div>
            <OurWork />
          </div>
          <div className='contactUs' data-aos="fade-in" data-aos-delay="150" data-aos-durration="2000">
            <ContactUs />
          </div>
        </>
  );
}

