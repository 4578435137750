import './AboutUs.css';

const AboutUs = () => {
    function handelScroll(){
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    return(
        <>
          <div className="text-section" id="aboutUs">
            <h1 className="title">About Us</h1>
            <button className="meetButton" onClick={handelScroll}>Contact Us Today</button>   
            
            <p>Our local, well-established, fully insured business has been family-
                owned and operated for over 35 years. We offer you a combined
                experience level of 100 plus years. Superior craftsmanship is our legacy
                - and has been featured in Milwaukee Magazine. Based out of
                Oconomowoc, we provide new and remodeling residential masonry to
                all Southeastern Wisconsin.</p>
            <p>You can count on our experience, quality workmanship, affordability,
                and professionalism, to ensure a job completed to your satisfaction. We
                are the best at what we do with a long history of satisfied customers.</p> 
          </div>
        </>
    )
}

export default AboutUs;