import React, { useState } from 'react';
import {Carousel} from "react-bootstrap";
import './OurWork.css';

const OurWork = () => {


    return (
        <div>
            <Carousel className="caro">
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/StoneVeneers.jpg")}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Stone Veneer</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/BrickPaving.jpg")}
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        <h3>Brick Paving</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/FireplaceFront.jpg")}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Fireplace Front</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/FireplaceFullMasonry.jpg")}
                        alt="Fourth slide"
                    />

                    <Carousel.Caption>
                        <h3>Fireplace Full Masonry</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/BrickVeneer.jpg")}
                        alt="Fith slide"
                    />

                    <Carousel.Caption>
                        <h3>Brick Veneer</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/ConcreteFill.jpg")}
                        alt="Sixth slide"
                    />

                    <Carousel.Caption>
                        <h3>Concrete Slab</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block"
                        style={{ width: '100%', height: 'auto', alignContent: 'center'}}
                        src={require("../WebsitePictures/FullFirePlace.jpg")}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Fireplace Full Masonry</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default OurWork;