import './Services.css';
import ServicesCard from './ServicesCard';

const Services = () => {
    return ( 
        <>
          <div className="services" id="services">
            <h1 className="title-services">Services</h1>
              <ul className="services_list">
                <li>Stone Veneers (full and thin)</li>
                <li>Brick Veneer and Brick Pavers</li>
                <li>Fireplace Fronts</li>
                <li>Fireplace Full Masonry</li>
                <li>Block Foundations</li>
                <li>Retaining Walls</li>
                <li>Skid Loader Work and Grading</li>
                <li>Concrete Flat Work
                  <ul className="services_sub_list">
                    <li>Floors</li>
                    <li>Slabs</li>
                    <li>Sidewalks</li>
                    <li>Driveways</li>
                    <li>Exposed Aggregate</li>
                  </ul> 
                </li>
              </ul>
              <p className="services_bottom_text">Contact us today, if you are looking for quality work at a great price!</p>
              <h5 className="free_estimates">Free Estimates Available</h5>
              <h6 className="founded">Founded by Dennis Grimm, 1986</h6>
          </div>
        </>
  )
}


export default Services;