import './Magazine.css';

const Magazine = () => {
    return(
        <div className="magazine_holder">
            <img className="magazine_image" src={require ('../WebsitePictures/MagazineCover.jpg')} alt="Magazine Cover" />
            <p className="magazine_text">
             Our bulilders have experience in a variety of home styles.
             We were featured in the 2018 edition of Milwaukee's Lifestyle Magazine.
             </p>
            
        </div>
    )
}

export default Magazine;